import { useLocation, useNavigate, Navigate } from 'react-router';

import LoginCard from "../components/login/LoginCard"
import PasswordCard from "../components/login/PasswordCard"
import { Button } from '@mui/material';
import { Typography } from '@mui/material';

import Logo from "../assets/img/official/img_login.svg"
import css from './Login.module.css'

const Login = ( { isAuthorized } ) => {

    const location = useLocation()
    const navigateTo = useNavigate()

    if ( ! isAuthorized ) {
        return(
            <div className={ css.content }>
                <div className={ css.section }>
                    
                    <img className={ css.logo } alt="West Telco" src={ Logo } />
                    
                    <Typography className={ css.appName } variant='h5' component='h5'>Session Scheduler</Typography>
                    <Typography className={ css.appEdition } variant='h6' component='h6'>Business Edition</Typography>
                    
                    {
                        location.pathname.indexOf('/activate-account') === 0 || location.pathname.indexOf('/recover-account') === 0 || location.pathname.indexOf('/change-password') === 0 ?
                            <PasswordCard /> :
                            <LoginCard />
                    }
                    
                    <Typography className={ css.tienesCuenta } variant='h5' component='h5'>¿Aún no tienes cuenta?</Typography>
                    <Button className={ css.btnCrearCuenta   } onClick={ () => navigateTo( '/register' ) } variant="contained" color="primary" size='large' >
                        CREAR AHORA
                    </Button>
                    <Typography className={ css.footer } variant='body1' component='p'>vPrealfa 1.0. © West Telco 2022. <a style={{color:'white'}} href='https://www.westtelco.com.mx/aviso-de-privacidad/' rel='noreferrer' target={"_blank"}> Aviso de Privacidad.</a> Todos los derechos reservados.</Typography>
                </div>
                
            </div >
        )
    }

    return <Navigate to={`/integraciones`} replace />

}

export default Login