import Encabezado from "./Encabezado"
import { Button } from "@mui/material"
import { Alert } from "@mui/material"
import { Typography } from "@mui/material"
import TextField from '@mui/material/TextField';

const DatosUsuario = () => {

    const alert = 'Tu reunión será agendada para el Domingo 31 de Julio a las 9:30 AM'

    return(
        <div>
            <Encabezado titulo='Solicitar Consulta con Dr. Espinoza' mensajeInicial={`Gracias por contactar al doctor Alán Esponizoa, utilice este formulario para agendar una consulta.`}/>

            <div className="d-flex flex-column mt-5">
                <div className="d-flex justify-content-between">
                    <Alert className='col-6 col-sm-9 col-md-9 col-lg-10' severity="info">{ alert }</Alert>
                    <Button size={'large'} variant='text' sx={ { textAlign: 'left',  textTransform: 'capitalize', fontSize: '1.1rem' } }>cambiar fecha</Button>
                </div>
            </div>

            <Typography className="my-4" sx={{ color: 'rgba(0, 0, 0, 0.6)', }} variant="h6" component='h6'>Para finalizar, proporciona los datos requeridos a continuación:</Typography>

            <TextField
                className='col-12 my-3'
                id="nombre"
                name="nombre"
                label="Nombre"
                value={''}
                onChange={() => { console.log('nombre.onChange') }}
                //error={formik.touched.email && Boolean(formik.errors.email)}
                //helperText={formik.touched.email && formik.errors.email}
            />

            <div className="d-flex justify-content-center">

                <TextField
                    className='col-6 my-3 pe-2'
                    id="aPaterno"
                    name="aPaterno"
                    label="Apellido Paterno"
                    value={''}
                    onChange={() => { console.log('aPaterno.onChange') }}
                    //error={formik.touched.email && Boolean(formik.errors.email)}
                    //helperText={formik.touched.email && formik.errors.email}
                />

                <TextField
                    className='col-6 my-3 ps-2'
                    id="aMaterno"
                    name="aMaterno"
                    label="Apellido Materno"
                    value={''}
                    onChange={() => { console.log('aMaterno.onChange') }}
                    //error={formik.touched.email && Boolean(formik.errors.email)}
                    //helperText={formik.touched.email && formik.errors.email}
                />

            </div>

            <TextField
                className='col-12 my-3'
                id="email"
                name="email"
                label="Correo Electrónico"
                value={''}
                onChange={() => { console.log('email.onChange') }}
                //error={formik.touched.email && Boolean(formik.errors.email)}
                //helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
                className='col-12 my-3'
                id="asunto"
                name="asunto"
                label="Asunto de la Reunión"
                value={''}
                onChange={() => { console.log('asunto.onChange') }}
                //error={formik.touched.email && Boolean(formik.errors.email)}
                //helperText={formik.touched.email && formik.errors.email}
            />

        </div>
    )

}

export default DatosUsuario