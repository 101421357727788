const Body = ( { children } ) => {

    return(
        <div className="componentBody d-flex col-12">
            { children }
        </div>
    )

}

export default Body