import Typography from '@mui/material/Typography';
import classes from './Content.module.css'

const Content = ({ children, title, contentMode }) => {
  const selectedMode = {
    "inline-wrap": "d-flex flex-wrap",
    "inline-nowrap": "d-flex flex-nowrap",
    column: "d-flex flex-column text-start",
  };

  return (
    <div className={ `${ classes.content } d-flex flex-column py-5 px-5 w-100` }>
      {
        // Opt out Title
        title && <div className="align-self-start"><Typography variant='h4'>{ title }</Typography></div>
      }

      <div className={ selectedMode[contentMode] }>{ children }</div>
    </div>
  );
};

export default Content;
