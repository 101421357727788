import { Routes, Route } from 'react-router-dom'
import { useEffect, useState } from 'react';
import Login from './views/Login';
import Register from './views/Register';
import Integraciones from './views/Integraciones';
import Reuniones from './views/Reuniones';
import Formulario from './views/Formulario';
import Solicitud from './views/Solicitud/Solicitud';
import Private from './components/Private';
import CuentaActivada from './views/CuentaActivada';
import { ContextProvider } from "./utility/Context";
import axios from 'axios';
import { getItem, setItem } from "./utility/localStorageControl";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ZoomCallback from './views/ZoomCallback';

function App() {

  const [authorized, setAuthorized] = useState(null)
  const token = getItem( 'westToken' )
  const user = getItem( 'westUser' )

  const attemptAccess = async () => {
    //console.log( `App => attemptAccess executed.` )
    if ( ! user || ! token ) {
      setAuthorized( false )
      return
    }

    try {
        const response = await axios.get( `${ process.env.REACT_APP_BACKEND_URI }auth`, { headers: {
            'authorization': `Bearer ${ token }`
        } } )
       
        setAuthorized(true)

    } catch ( error ) {
        //console.log( error )
        if ( error.response.status === 403 ) {
          //console.log( `App > Attempting to refresh the token...` )
          const refresh = await axios.post( `${ process.env.REACT_APP_BACKEND_URI }auth/refreshtoken`, { 'refreshToken': user.refreshToken } )
          //console.log( refresh )
          //console.log( refresh.data )
          setItem( 'westToken', refresh.data.accessToken )
          if ( refresh.status === 200 ) {
            setAuthorized( true )
          }
        }
        if ( error.response.status !== 200 ) {
          //console.warn( error.response )
          setAuthorized( false )
        }
    }
  }

  useEffect( () => {
    attemptAccess()
  }, [authorized] )

  return (
    <div className="App d-flex flex-column align-items-center w-100 h-100">
      <ContextProvider>
        <Routes>
          <Route exact path={ `/` } element={ <Login isAuthorized={ authorized }/> } />
          <Route exact path={ `/register` } element={ <Register /> } />
          <Route exact path={ `/change-password/:usuarioId` } element={ <Login /> } />
          <Route exact path={ `/recover-account/:usuarioId/:token` } element={ <Login /> } />
          <Route exact path={ `/integraciones` } element={ <Private isAuthorized={ authorized } token={ token } setAuthorized={ setAuthorized }><Integraciones /></Private> } />
          <Route exact path={ `/reuniones` } element={ <Private isAuthorized={ authorized } token={ token } setAuthorized={ setAuthorized }><Reuniones /></Private> } />
          <Route exact path={ `/formulario` } element={ <Private isAuthorized={ authorized } token={ token } setAuthorized={ setAuthorized }><Formulario /></Private> } />
          <Route exact path={ `/solicitud` } element={ <Solicitud /> } />
          <Route exact path={ `/activacion` } element={ <CuentaActivada /> } />
          <Route exact path={ `/zoomcallback` } element={ <ZoomCallback /> } />
        </Routes>
      </ContextProvider>
    </div>
  );
}

export default App;
