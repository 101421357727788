import React from 'react';

const FooterSolicitud = () => {
    return (
        <div className="footer-component d-flex align-items-center justify-content-center">
            vPrealfa 1.0. © West Telco 2022.<a href='https://www.westtelco.com.mx/aviso-de-privacidad/' rel='noreferrer' target={"_blank"} > Aviso de Privacidad.</a> Todos los derechos reservados.
        </div >
    );
}

export default FooterSolicitud;