import Header from '../components/layout/Header/Header'
import Body from '../components/layout/Body'
import Footer from '../components/layout/Footer'
import SideBar from '../components/layout/SideBar/SideBar'
import Content from '../components/layout/Content'
import React, { useState, useEffect } from 'react'
import { Image } from 'react-bootstrap'
import { Button, FormControl, InputLabel } from '@mui/material';
import { Select } from '@mui/material'
import { MenuItem } from '@mui/material'
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getItem, setItem } from "../utility/localStorageControl";
import { sendRequest } from '../utility/utility'
import axios from 'axios'


const Formulario = () => {

    const user = getItem( `westUser` )
    const token = getItem( `westToken` )
    const localForm = getItem( `localForm` )

    const [ form, setForm ] = useState( localForm )
    const [ fileSelect, setFileSelect ] = useState( null )
    const [ fileURL, setFileURL ] = useState( localForm.logotipo )


    const handleChange = event => {
        setForm( prevForm => ({
            ...prevForm,
            [event.target.name]: event.target.value,
        }) )
    }

    const handleChangeCampo = (event, index, property) => {
        setForm( prevForm => {
           const camposObligatorios = [...prevForm.camposObligatorios]
           camposObligatorios[index] = { ...camposObligatorios[index], [property]: event.target.value }
           return { ...prevForm, camposObligatorios }
        } )
    }

    const handleFileSelect = async event => {        

        setFileSelect( event.target.files[0] )

        try {
            const endpoint = `${ process.env.REACT_APP_BACKEND_URI }formularios/logo`
            const file = event.target.files[0]
            uploadFile( endpoint, file )

        } catch ( error ) {
            console.warn( error )
        }
    }

    const handleChangeCampoAdicional = async (event, index, property) => {
        setForm( prevForm => {
            const camposAdicionales = [...prevForm.camposAdicionales]
            camposAdicionales[index] = { ...camposAdicionales[index], [property]: event.target.value }
            return { ...prevForm, camposAdicionales }
        } )
    }

    const handleAddCampo = async () => {
        setForm( prevForm => {
            const camposAdicionales = [...prevForm.camposAdicionales, {
                titulo: "Campo Adicional",
                tipo: "text",
                inputId: "testInputId",
            }]
            return { ...prevForm, camposAdicionales }
        } )
    }

    const handleRemoveCampo = async index => {
        setForm( prevForm => {
            const camposAdicionales = [...prevForm.camposAdicionales]
            camposAdicionales.splice( index, 1 )
            return { ...prevForm, camposAdicionales }
        } )
    }

    const uploadFile = ( url, file ) => {
        const formData = new FormData()
        formData.append( `usuarioId`, user.userId )
        formData.append( `currentFile`, fileURL )
        formData.append( `file`, file )

        axios.post( url, formData, {
            headers: {
                "method": "POST",
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${ token }`,
            }
        } ).then( response => {
            setFileURL( response.data.imageUrl )
            updateLocalForm()
        } ).catch( error => {
            console.error( error )
        } )
    }

    const saveForm = async () => {
        console.log(form)
        setItem( 'localForm', JSON.stringify( form ) )
        const route = "formularios";
        const request = {
            method: 'post',
            headers: {
                "method": "POST",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${ token }`,
            },
            data: form
        };
        return await sendRequest(route, request)
        
    }

    const updateLocalForm = async () => {
        const route = `formularios/${ user.userId }`
        const request = {
            method: 'get',
            headers: {
                'method': 'GET',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${ token }`
            }
        }
        try {
            const response = await sendRequest( route, request )
            setItem( 'localForm', JSON.stringify( response.data ) )
        } catch ( error ) {
            console.warn( error )
        }
    }

    useEffect( () => {
        const getForm = async () => {
            const route = `formularios/${ user.userId }`;
            const request = {
                method: 'get',
                headers: {
                    "method": "GET",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${ token }`,
                },
            };
            try {
                const response = await sendRequest(route, request)
                setForm( response.data )
            } catch ( error ) {
                console.error( error )
            }
            
        }

        getForm().catch( console.error )

    }, [] )

    return(
        <div className='d-flex flex-column pageBody'>
            <Header />
            <Body>
                <SideBar/>
                <Content contentMode={ `inline-wrap` } title={ `Configurar Formulario para agendar reuniones` }>

                    <div className='mt-5 d-flex flex-wrap w-100'>

                        <div className='d-flex flex-column col-12 col-xl-7 align-items-start text-left'>

                            <div className='d-flex flex-column align-items-start text-left'>
                                <Typography className='text-dark align-items-start text-left' variant='h6' component='h6'>Logotipo</Typography>
                                <Image thumbnail style={ { width: 150 } } src={ fileURL ? fileURL : 'https://via.placeholder.com/160x60?text=Logotipo' } />
                                <Button
                                    size={'medium'}
                                    variant='text'
                                    component='label'
                                    sx={ { textTransform: 'capitalize', fontSize: '1.0rem' } }
                                >
                                    Cambiar imagen
                                    <input
                                        type='file'
                                        hidden
                                        accept='image/png, image/jpg, image/jpeg, image/webp'
                                        onChange={ handleFileSelect }
                                    />
                                </Button>
                            </div>

                            <TextField
                                className='col-12 my-3'
                                variant='standard'
                                id="titulo"
                                name="titulo"
                                label="Título del Formulario"
                                value={ form.titulo ? form.titulo : '' }
                                onChange={ handleChange }
                                //error={formik.touched.email && Boolean(formik.errors.email)}
                                //helperText={formik.touched.email && formik.errors.email}
                            />

                            <TextField
                                className='col-12 my-3'
                                multiline
                                rows={ 2 }
                                id="mensajeInicial"
                                name="mensajeInicial"
                                label="Mensaje Inicial"
                                value={ form.mensajeInicial ? form.mensajeInicial : '' }
                                onChange={ handleChange }
                                //error={formik.touched.email && Boolean(formik.errors.email)}
                                //helperText={formik.touched.email && formik.errors.email}
                            />

                            <hr className='mt-2 w-100' />

                            <Typography className='text-dark contentText' variant='h4'>Configurar campos del formulario</Typography>

                            <Typography className='my-4' variant='subtitle1'>Colección de datos</Typography>

                            {
                                form.camposObligatorios.map( (campo, index) => {
                                    return (
                                        <div key={ index } className='d-flex flex-column align-items-center w-100'>
                                            <div className='d-flex w-100'>
                                                <div className='d-flex col-6 pe-2 align-items-center'>
                                                    <TextField
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        className='col-12 my-3'
                                                        id={ campo.titulo.replace(/\s+/g, '') }
                                                        name={ campo.titulo.replace(/\s+/g, '') }
                                                        label={ `Titulo del campo` }
                                                        variant='standard'
                                                        value={ campo.titulo ? campo.titulo : '' }
                                                        onChange={ event => handleChangeCampo(event, index, 'titulo') }
                                                        //error={formik.touched.email && Boolean(formik.errors.email)}
                                                        //helperText={formik.touched.email && formik.errors.email}
                                                    />
                                                </div>

                                                <div className='d-flex col-6 ps-2 align-items-center'>
                                                    <FormControl fullWidth>
                                                        <InputLabel id='campoTipo'>
                                                            Tipo de Campo
                                                        </InputLabel>
                                                        <Select
                                                            readOnly
                                                            labelId={ `${campo.titulo.replace(/\s+/g, '')}_${campo.tipo}` }
                                                            id={ `${campo.titulo.replace(/\s+/g, '')}_${campo.tipo}` }
                                                            value={ campo.tipo }
                                                            label="Tipo de Campo"
                                                            onChange={ event => handleChangeCampo(event, index, 'tipo') }
                                                        >
                                                            <MenuItem value={ 'text' }>Texto libre</MenuItem>
                                                            <MenuItem value={ 'email' }>Correo electrónico</MenuItem>
                                                            <MenuItem value={ 'textarea' }>Texto libre (multi-línea)</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                
                                            </div>

                                            <hr className='mt-2 w-100' />

                                        </div>
                                    )
                                } )
                            }

                            {
                                form.camposAdicionales.map( (campo, index) => {
                                    return (
                                        <div key={ index } className='d-flex flex-column align-items-center w-100'>
                                            <div className='d-flex w-100'>
                                                <div className='d-flex col-6 pe-2 align-items-center'>
                                                    <TextField
                                                        className='col-12 my-3'
                                                        id={ campo.titulo.replace(/\s+/g, '') }
                                                        name={ campo.titulo.replace(/\s+/g, '') }
                                                        label={ `Titulo del campo` }
                                                        variant='standard'
                                                        value={ campo.titulo ? campo.titulo : '' }
                                                        onChange={ event => handleChangeCampoAdicional(event, index, 'titulo') }
                                                        //error={formik.touched.email && Boolean(formik.errors.email)}
                                                        //helperText={formik.touched.email && formik.errors.email}
                                                    />
                                                </div>

                                                <div className='d-flex col-5 ps-2 align-items-center'>
                                                    <FormControl fullWidth>
                                                        <InputLabel id='campoTipo'>
                                                            Tipo de Campo
                                                        </InputLabel>
                                                        <Select
                                                            labelId={ `${campo.titulo.replace(/\s+/g, '')}_${campo.tipo}` }
                                                            id={ `${campo.titulo.replace(/\s+/g, '')}_${campo.tipo}` }
                                                            value={ campo.tipo }
                                                            label="Tipo de Campo"
                                                            onChange={ event => handleChangeCampoAdicional(event, index, 'tipo') }
                                                        >
                                                            <MenuItem value={ 'text' }>Texto libre</MenuItem>
                                                            <MenuItem value={ 'email' }>Correo electrónico</MenuItem>
                                                            <MenuItem value={ 'textarea' }>Texto libre (multi-línea)</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                
                                                <div className='d-flex col-1 ps-2 align-items-center'>
                                                    <Button variant='text' onClick={ () => handleRemoveCampo(index) }><DeleteForeverIcon/></Button>
                                                </div>
                                                
                                            </div>

                                            <hr className='mt-2 w-100' />
                                            
                                            

                                        </div>
                                    )
                                } )
                            }
                                
                                {
                                    (form.camposAdicionales === false || form.camposAdicionales.length < 5) &&
                                    (
                                        <Button
                                            className='align-self-start'
                                            size={'large'}
                                            variant='text'
                                            sx={ { textTransform: 'capitalize', fontSize: '1.1rem' } }
                                            onClick={ handleAddCampo }
                                        >
                                            Añadir Campo
                                        </Button>
                                    )
                                }

                            <hr className='my-3 w-100' />

                            <Typography variant='h4'>Configurar mensaje de confirmación</Typography>

                            <br />

                            <TextField
                                className='col-12 my-3'
                                multiline
                                rows={ 2 }
                                id="mensajeConfirmacion"
                                name="mensajeConfirmacion"
                                label="Mensaje de Confirmación"
                                value={ form.mensajeConfirmacion ? form.mensajeConfirmacion : '' }
                                onChange={ handleChange }
                                //error={formik.touched.email && Boolean(formik.errors.email)}
                                //helperText={formik.touched.email && formik.errors.email}
                            />

                            <Button
                                className='btnScheduler text-uppercase align-self-end mt-4'
                                type="submit"
                                variant="contained"
                                onClick={ saveForm }
                            >
                                ACTUALIZAR
                            </Button>

                        </div>
                        <div className='d-flex mt-5 mt-xl-0 flex-column col-12 col-xl-5'>
                            <div className='d-flex flex-column border col-11 p-4 mx-4'>
                                
                                <div className='d-flex justify-content-between mb-4'>
                                    <div className='d-flex col-10'>
                                        <Typography variant='h6'>Botón HTML</Typography>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex col-8'>
                                        <Typography variant='subtitle1'>URL Público</Typography>
                                    </div>
                                    <div className='d-flex col-4 justify-content-end'>
                                        <ContentCopyIcon sx={{ color: '#75799f' }} />
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex col-8'>
                                        <Typography sx={{ wordBreak: 'break-word', textAlign: 'left', }} variant='body2'>{ user.buttonLink ? user.buttonLink : '' }</Typography>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex col-8'>
                                        <Button size={'large'} variant='text' sx={ { textAlign: 'left',  textTransform: 'capitalize', fontSize: '0.9rem', paddingLeft: 0, } }>Abrir Portal Público</Button>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between mb-5'>
                                    <div className='d-flex col-8 justify-content-between'>
                                        <Typography variant='subtitle1'  sx={ { textAlign: 'left' } }>HTML para incrustar botón</Typography>
                                    </div>
                                    <div className='d-flex col-4 justify-content-end'>
                                        <ContentCopyIcon sx={{ color: '#75799f' }}/>
                                    </div>
                                </div>

                                <div className='d-flex flex-column align-items-start'>                                    
                                    <Typography variant='subtitle1' sx={ { textAlign: 'left' } }>Prevista del botón</Typography>
                                    <Button size={'medium'} variant='contained' sx={ { textTransform: 'capitalize', fontSize: '0.9rem' } }>AGENDAR SESIÓN</Button>                                    
                                </div>              

                            </div>
                            
                        </div>

                    </div>
                    

                </Content>
            </Body>            
            <Footer />
        </div>
    )

}

export default Formulario