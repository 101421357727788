import Encabezado from "./Encabezado"
import { Alert } from "@mui/material"
import './Confirmacion.css'

const Confirmacion = () => {

    const alert = 'Gracias por agendar tu consulta con el doctor Alán Espinoza.'

    return(
        <div>
            <Encabezado titulo={`La solicitud se ha creado con éxito`}/>
            <Alert className='col-12 mt-4' severity="info">{ alert }</Alert>
        </div>
    )

}

export default Confirmacion