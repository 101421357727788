import { useEffect, useState } from 'react'
import Header from '../components/layout/Header/Header'
import Body from '../components/layout/Body'
import Footer from '../components/layout/Footer'
import SideBar from '../components/layout/SideBar/SideBar'
import Content from '../components/layout/Content'
import Typography from '@mui/material/Typography';
import { Checkbox, FormControlLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterDayjs';
import dayjs from 'dayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TimePicker } from '@mui/lab';
import { FormGroup } from 'react-bootstrap'
import { sendRequest } from "../utility/utility";
import { getItem, setItem } from "../utility/localStorageControl";

const Reuniones = ( ) => {

    const user = getItem( `westUser` )
    const token = getItem( `westToken` )
    const localSettings = getItem( `localSettings` )


    const [ preferences, setPreferences ] = useState( localSettings )

    const handleDayChange = event => {
        setPreferences( prevPreferences => ({
            ...prevPreferences,
            [event.target.name]: event.target.checked
        }) )
    }

    const handleTimeChange = (newValue, inputName ) => {
        setPreferences( prevPreferences => ({
            ...prevPreferences,
            [inputName]: newValue
        }) )
    }

    useEffect( () => {
        const getPreferences = async () => {
            const route = `preferencias/${ user.userId }`;
            const request = {
                method: 'get',
                headers: {
                    "method": "GET",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${ token }`,
                },
            };
            try {
                const response = await sendRequest(route, request)
                setPreferences( response.data )
            } catch ( error ) {
                console.warn( error )
            }
            
        }

        getPreferences().catch( console.error )

    }, [] )

    useEffect( () => {
        const savePreferences = async (preferences) => {
            //console.warn(preferences)
            setItem( 'localSettings', JSON.stringify( preferences ) )
            const route = "preferencias";
            const request = {
                method: 'post',
                headers: {
                    "method": "POST",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${ token }`,
                },
                data: preferences
            };
            return await sendRequest(route, request)
            
        }

        if ( preferences ) savePreferences( preferences ).catch( console.error )
        //setItem( 'localSettings', JSON.stringify( preferences ) )
        

    }, [preferences] )    

    return(
        <div className='d-flex flex-column pageBody'>
            <Header />
            <Body>
                <SideBar/>
                <Content contentMode={ `inline-wrap` } title={ `Preferencias de fecha y horarios` }>
                    <div className='py-4 col-8 text-left'>

                        <Typography className='text-dark contentText' variant='body1'>Selecciona los días de la semana en los que deseas recibir solicitudes de reuniones.</Typography>

                        <FormGroup>
                            <div className='d-flex justify-content-start align-items-center py-3'>
                                <FormControlLabel className='col-3 px-4' control={ <Checkbox name='monday' checked={ preferences ? preferences.monday : false } onChange={ handleDayChange } size='medium' />} label={ <Typography variant="body1" >Lunes</Typography> }/>
                                <FormControlLabel className='col-3 px-4' control={ <Checkbox name='tuesday' checked={ preferences ? preferences.tuesday : false } onChange={ handleDayChange } size='medium' />} label={ <Typography variant="body1" >Martes</Typography> } />
                                <FormControlLabel className='col-3 px-4' control={ <Checkbox name='wednesday' checked={ preferences ? preferences.wednesday : false } onChange={ handleDayChange } size='medium' />} label={ <Typography variant="body1" >Miercoles</Typography> } />
                                <FormControlLabel className='col-3 px-4' control={ <Checkbox name='thursday' checked={ preferences ? preferences.thursday : false } onChange={ handleDayChange } size='medium' />} label={ <Typography variant="body1" >Jueves</Typography> } />
                            </div>

                            <div className='d-flex justify-content-start align-items-center'>
                                <FormControlLabel className='col-3 px-4' control={ <Checkbox name='friday' checked={ preferences ? preferences.friday : false } onChange={ handleDayChange } size='medium' />} label={ <Typography variant="body1" >Viernes</Typography> } />
                                <FormControlLabel className='col-3 px-4' control={ <Checkbox name='saturday' checked={ preferences ? preferences.saturday : false } onChange={ handleDayChange } size='medium' />} label={ <Typography variant="body1" >Sábado</Typography> } />
                                <FormControlLabel className='col-3 px-4' control={ <Checkbox name='sunday' checked={ preferences ? preferences.sunday : false } onChange={ handleDayChange } size='medium' />} label={ <Typography variant="body1" >Domingo</Typography> } />
                            </div>
                        </FormGroup>

                        <Typography className='text-dark contentText py-5' variant='body1'>Selecciona el horario en el que deseas recibir solicitudes de reuniones.</Typography>

                        <FormGroup>

                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <div className='d-flex'>
                                    <div className='col-4 col-xl-3 pe-2'>
                                        <TimePicker
                                            minutesStep={ 30 }
                                            label="Inicio"
                                            value={ preferences ? preferences.horaInicial : dayjs("0000-01-01T" + "00:00") }
                                            openTo='hours'
                                            shouldDisableTime={ (timeValue, clockType) => {
                                                if ( clockType === 'minutes' && ( timeValue !== 0 && timeValue !== 30 )  ) {
                                                    return true
                                                }
                                                return false
                                            } }
                                            onChange={ newValue => handleTimeChange(newValue, 'horaInicial') }
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </div>
                                    <div className='col-4 col-xl-3 ps-2'>
                                        <TimePicker
                                            minutesStep={ 30 }
                                            label="Fin"
                                            value={ preferences ? preferences.horaFinal : dayjs("0000-01-01T" + "01:00") }
                                            openTo='hours'
                                            shouldDisableTime={ (timeValue, clockType) => {
                                                if ( clockType === 'minutes' && ( timeValue !== 0 && timeValue !== 30 )  ) {
                                                    return true
                                                }
                                                return false
                                            } }
                                            onChange={ newValue => handleTimeChange(newValue, 'horaFinal') }
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </div>
                                </div>                                
                            </LocalizationProvider>
                        
                        </FormGroup>

                        <hr className='mt-4 mb-5'/>

                        <Typography className='text-dark contentText' variant='body1'>Las opciones disponibles para los usuarios que deseen agendar reuniones por medio de este servicio se sincronizarán con tu calendario de Zoom.</Typography>

                    </div>
                </Content>
                
            </Body>            
            <Footer />
        </div>
    )

}

export default Reuniones