//import { makeStyles } from '@mui/styles';
import classes from './SideBar.module.css'
import List from '@mui/material/List';
import { ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InputIcon from '@mui/icons-material/Input';
import EventIcon from '@mui/icons-material/Event';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

const SideBar = () => {

    const history = useNavigate()
    const location = useLocation()

    return(
        <div className={ `${ classes.sideBar } col-3 d-inline-block side-bar` }>
                <div className={classes.drawerContainer}>
                    <List>
                        <div className="col-12 pl-4 d-flex justify-content-start side-title list">
                            CONFIGURACIONES
                        </div>
                        <ListItemButton selected={location.pathname === "/integraciones"} className="list" onClick={() => history("/integraciones")} >
                            <ListItemIcon> <InputIcon /></ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={<Typography type="body2">Integraciones</Typography>}
                            />
                        </ListItemButton>
                        <ListItemButton selected={location.pathname === "/reuniones"} className="list" onClick={() => history("/reuniones")} >
                            <ListItemIcon> <EventIcon /></ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={<Typography type="body2">Configurar reuniones</Typography>}
                            />
                        </ListItemButton>
                        <ListItemButton className="list" selected={location.pathname === "/formulario"} onClick={() => history("/formulario")} >
                            <ListItemIcon> <PowerInputIcon /></ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={<Typography type="body2">Formulario y estilo</Typography>}
                            />
                        </ListItemButton>

                    </List>
                </div>
            </div>
    )

}

export default SideBar