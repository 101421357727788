import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Alert, ButtonBase } from '@mui/material';
import { register } from '../../services/register';
import { postLogin } from '../../services/login'
import { setItem } from '../../utility/localStorageControl';
import { useFormik } from 'formik';
import { sendRequest } from '../../utility/utility';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import axios from 'axios';


function RegisterCard() {

    const getPreferences = async (user, token) => {
        const route = `preferencias/${ user.userId }`;
        const request = {
            method: 'get',
            headers: {
                "method": "GET",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${ token }`,
            },
        };
        try {
            const response = await sendRequest(route, request)
            setItem( 'localSettings', JSON.stringify( response.data ) )
        } catch ( error ) {
            console.warn( error )
        }
        
    }

    const getForms = async ( user, token ) => {
        const route = `formularios/${ user.userId }`
        const request = {
            method: 'get',
            headers: {
                'method': 'GET',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${ token }`
            }
        }
        try {
            const response = await sendRequest( route, request )
            setItem( 'localForm', JSON.stringify( response.data ) )
        } catch ( error ) {
            console.warn( error )
        }
    }

    const validationSchema = () => {
        return yup.object({
            nombre: yup
                .string('Nombre')
                .matches(
                    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                        'El nombre solo puede contener caraceres válidos.'
                    )
                .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms,'Por favor introduzca su nombre completo.'),
            email: yup
                .string('Email')
                .email('El Email debe tener un formato válido.')
                .required('Se requiere email'),
            password: yup
                .string('Contraseña')
                .min(8, 'Tu contraseña debe ser mayor de 6 caracteres')
                .required('Se requiere ingresar contraseña'),
            passwordConfirmation: yup
            .string('Confirmación de Contraseña')
            .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir.')
            .min(8, 'Tu contraseña debe ser mayor de 6 caracteres.')
            .required('Se requiere ingresar la confirmación de la contraseña.'),
            licenseKey: yup
            .string('Clave de Licencia')
            .required('Se requiere ingresar tu clave de licencia.')
        });
    }

    const formik = useFormik({
        initialValues: {
            nombre: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            licenseKey: '',
            buttonLink: '',
        },
        validationSchema: validationSchema,
        // Cambiar esto por Registro en lugar de Login
        onSubmit: (values) => {
            setAlert({ active: false, text: '', severity: 'error' })
            setError()

            const data = {
                "nombre": values.nombre,
                "email": values.email,
                "password": values.password,
                "passwordConfirmation": values.passwordConfirmation,
                "licenseKey": values.licenseKey,
            }

            const headers = {
                "method": "POST",
                "Content-Type": "application/json",
                "Accept": "application/json"
            }

            axios.post( `${ process.env.REACT_APP_BACKEND_URI }usuarios/`, data, headers ).then( res => {
                Swal.fire({
                    title: 'Usuario Registrado',
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                }).then( () => {
                    history("/")
                    return true
                })
            } ).catch( error => {
                //console.dir( error.response )
                setError(error.response.data.errors[0].msg)
            } )
            /*
            register( values.nombre, values.email, values.password, values.passwordConfirmation, values.licenseKey ).then( res => {
                Swal.fire({
                    title: 'Usuario Registrado',
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                }).then( () => {
                    history("/")
                    return true
                })
            } ).catch( error => {
                Swal.fire({
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                  })
            } )
            */
            /*register(values.nombre, values.email, values.password, values.passwordConfirmation, values.licenseKey).then(res => {
                if (res.success !== false) {
                    // If register successful, login user automatically
                    
                    postLogin(values.email, values.password).then(res => {
                        //console.log(res)
                        if (res.success !== false) {
                            setItem('westToken', res.data.accessToken)
                            let user = {
                                refreshToken: res.data.refreshToken,
                                userEmail: res.data.email,
                                userId: res.data.userId,
                                userName: res.data.nombre,
                                activo: res.data.activo,
                                buttonLink: res.data.buttonLink,
                            }
                            setItem('westUser', JSON.stringify(user))
                            getPreferences(user, res.data.accessToken)
                            getForms( user, res.data.accessToken )
                            history("/integraciones")
                            return true
                        }
                        else {
                            setError("Credenciales inválidas")
                        }
                    })
                    
                    return true
                }
                else {                    
                    //console.warn(res.error.response.data.errors[0])
                    //setError(res.error.response.data.errors[0].msg)
                    setError(res.error.response.data.errors[0].msg)
                }
            })*/
        },
    });

    const history = useNavigate()

    const [error, setError] = useState();
    const [alert, setAlert] = useState({ active: false, text: '', severity: 'error' })

    return (
            <form id="registration" onSubmit={formik.handleSubmit} className="card col-12 col-md-3 d-flex justify-content-center register-card mt-5 mb-4 pt-4 pb-5 px-4 w-100">

                <div className='d-flex flex-column px-5'>
                    <Typography className="mt-4 mb-4" variant="h3" component="h2">
                        Crear Cuenta
                    </Typography>

                    <TextField
                        className='my-3'
                        id="nombre"
                        name="nombre"
                        label="Nombre y Apellidos"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                        helperText={formik.touched.nombre && formik.errors.nombre}
                    />

                    <TextField
                        className='my-3'
                        id="email"
                        name="email"
                        label="Correo electrónico"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />

                    <Typography className="mt-4 mb-4" variant="h5" component="h2">
                        Crear Contraseña
                    </Typography>

                    <TextField
                        className='my-3'
                        id="password"
                        name="password"
                        label="Contraseña"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />

                    <TextField
                        className='my-3'
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        label="Confirmación de Contraseña"
                        type="password"
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange}
                        error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                        helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                    />

                    <Typography className="mt-4 mb-4" variant="h5" component="h2">
                        Clave de Licencia
                    </Typography>

                    <TextField
                        className='my-3'
                        id="licenseKey"
                        name="licenseKey"
                        label="Clave de Licencia"
                        type="text"
                        autoComplete='off'
                        value={formik.values.licenseKey}
                        onChange={formik.handleChange}
                        error={formik.touched.licenseKey && Boolean(formik.errors.licenseKey)}
                        helperText={formik.touched.licenseKey && formik.errors.licenseKey}
                    />

                    {error ?
                    (<Alert icon={false} severity="error">
                        {error}
                    </Alert>) : ""}

                    <div className='d-flex w-100 justify-content-end mt-4'>
                        <Button className='col-4 btnScheduler' type="submit" variant="contained" color="primary" size='lg' disabled={formik.values.password && formik.values.passwordConfirmation && formik.values.email && formik.values.licenseKey ? false : true}>
                            CREAR PERFIL
                        </Button>
                    </div>
                    

                </div>
                
            </form >
    );
}

export default RegisterCard;