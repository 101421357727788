import Profile from './Profile'
import { getItem } from '../../../utility/localStorageControl'
import { useLocation } from 'react-router-dom'
import Logo from '../../../assets/img/official/img_header.svg'

const Header = ( { isAnonymous } ) => {

    const location = useLocation()

    return(
        <div className={`App-header col-12 p-2 pt-3 pb-3 fixed-top`}>
            {/*getItem('westToken') === null && location.pathname.indexOf('/session') === -1 ? <Redirect to='/' /> : ''*/}
            <div className='d-flex justify-content-between w-100'>
                <div className="col d-flex align-items-center justify-content-start align-self-center">
                    <div className="header-logo">
                        <img alt="Logo" src={Logo} className="img-logo" />
                    </div>
                    <div className="col-9 d-flex align-items-center hd-txt px-3">
                        Session Scheduler
                    </div>
                </div>
                {
                    !isAnonymous &&
                        (
                            <div className="col d-flex align-items-center justify-content-end align-self-center ">
                                <Profile anonymus={getItem('westToken') === null && location.pathname.indexOf('/session') !== -1 ? true : false} />
                            </div>
                        )
                }
                
            </div>
        </div >
    )

}

export default Header