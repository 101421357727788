import Header from "../components/layout/Header/Header"
import Footer from "../components/layout/Footer"
import { useSearchParams, useNavigate } from "react-router-dom"
import { Typography } from "@mui/material"
import { Button } from "@mui/material"
import { Link } from "react-router-dom"

const CuentaActivada = () => {

    const [ urlParams ] = useSearchParams()
    const status = urlParams.get('status')

    return(
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <Header isAnonymous />
        <div className="d-flex justify-content-center registerBody w-100">
            {
                status === 'valid' ?
                    <Valido/>
                    :
                    <Invalido/>
            }
        </div>
        <Footer/>
    </div>
    )

}

const Valido = () => {
    const redirectTo = useNavigate()

    return (
        <div className="d-flex flex-column align-items-center justify-content-center registerBody w-100">
            <Typography variant="h3" component="h3">Cuenta Activada</Typography>
            <div className="col-md-6 my-3">
                <Typography className="text-center" variant="body" component="p">
                    Gracias por confirmar tu correo y activar tu cuenta en Session Scheduler. Ahora puedes iniciar sesión.
                </Typography>
                
            </div>
            <Button size={'medium'} variant='contained' sx={ { fontSize: '0.9rem' } } onClick={ () => { redirectTo( `/`, { replace: true } ) } }>Iniciar sesión</Button>
        </div>
    )    
}

const Invalido = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center registerBody w-100">
            <Typography variant="h3" component="h3">Oops!</Typography>
            <div className="col-md-6 my-3">
                <Typography className="text-center" variant="body" component="p">
                    Ha habido un error.<br/>
                    El enlace de activación parece haber expirado o ser inválido.
                    Si no has activado ésta cuenta previamente y recibiste el correo para activar tu cuenta, 
                    por favor comunícate a <br/>
                </Typography>
            </div>
            <Link to={`mailto:soporte-plataforma@westtelco.com.mx`}>soporte-plataforma@westtelco.com.mx</Link>
        </div>
    )
}

export default CuentaActivada