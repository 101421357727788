import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setItem } from '../utility/localStorageControl';
import axios from 'axios';

const ZoomCallback = () => {

    const [ query ] = useSearchParams()

    useEffect( () => {
        const postCode = async () => {
            const currentParams = Object.fromEntries([...query])

            try {
                const sendcode = await axios.post( `${ process.env.REACT_APP_BACKEND_URI }zoom/auth/login/`, {
                    authCode: currentParams.code,
                }, {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                } )
                console.log(sendcode.data)
            } catch ( error ) {
                console.warn( error )
            }
            
        }
        postCode()
    }, [ query ] )

}

export default ZoomCallback