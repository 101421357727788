import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Alert, ButtonBase, Card } from '@mui/material';
import { postForgotPass } from '../../services/login';
import { setItem } from '../../utility/localStorageControl';
import { useFormik } from 'formik';
import { sendRequest } from '../../utility/utility';
import * as yup from 'yup';
import axios from 'axios';
import css from './LoginCard.module.css'


function LoginCard() {

    const validationSchema = () => {
        if (forgotPass) {
            return yup.object({
                email: yup
                    .string('Email')
                    .email('Introduce un formato válido')
                    .required('Se requiere email'),
            });
        }
        else {
            return yup.object({
                email: yup
                    .string('Email')
                    .email('Ingresa un email valido')
                    .required('Se requiere email'),
                password: yup
                    .string('Contraseña')
                    .min(6, 'Tu contraseña debe ser mayor a 6 caracteres')
                    .required('Se requiere ingresar la contraseña'),
            });
        }
    }

    const getPreferences = async (user, token) => {
        const route = `preferencias/${ user.userId }`;
        const request = {
            method: 'get',
            headers: {
                "method": "GET",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${ token }`,
            },
        };
        try {
            const response = await sendRequest(route, request)
            setItem( 'localSettings', JSON.stringify( response.data ) )
        } catch ( error ) {
            console.warn( error )
        }
        
    }

    const getForms = async ( user, token ) => {
        const route = `formularios/${ user.userId }`
        const request = {
            method: 'get',
            headers: {
                'method': 'GET',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${ token }`
            }
        }
        try {
            const response = await sendRequest( route, request )
            setItem( 'localForm', JSON.stringify( response.data ) )
        } catch ( error ) {
            console.warn( error )
        }
    }

    //getPreferences().catch( console.error )
    //getForms().catch( console.error )

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setAlert({ active: false, text: '', severity: 'error' })
            setError()
            if (forgotPass) {
                postForgotPass(values.email).then(res => {
                    if (res.success !== false) {
                        setAlert({ active: true, text: "Hemos enviado un correo electrónico con las instrucciones para recuperar tu contraseña.", severity: "success" })
                    }
                    else {
                        setAlert({ active: true, text: "No se ha podido localizar una cuenta asociada con ese email. Intenta de nuevo.", severity: "error" })
                    }
                })
            }
            else {
                // Attempt Login
                axios.post( `${ process.env.REACT_APP_BACKEND_URI }usuarios/login`, {
                    "email": values.email,
                    "password": values.password,
                } ).then( response => {
                    setItem('westToken', response.data.accessToken)
                        setItem( 'localSettings', JSON.stringify( response.data ) )
                        let user = {
                            refreshToken: response.data.refreshToken,
                            userEmail: response.data.email,
                            userId: response.data.userId,
                            userName: response.data.nombre,
                            activo: response.data.active,
                            empresa: response.data.empresa,
                            buttonLink: response.data.buttonLink,
                        }
                        setItem('westUser', JSON.stringify(user))
                        getPreferences(user, response.data.accessToken)
                        getForms( user, response.data.accessToken )
                        history("/integraciones")
                        return true
                } ).catch( error => {                    
                    setError(error.response.data.message)
                } )
            }
        },
    });

    const history = useNavigate()

    const [error, setError] = useState();
    const [alert, setAlert] = useState({ active: false, text: '', severity: 'error' })
    const [sendEmail, setSendEmail] = useState(false)
    const [forgotPass, setForgotPass] = useState(false)

    return (
        <Card className={ css.loginCard }>
             <form className={ css.form } onSubmit={formik.handleSubmit} >
                {
                    forgotPass ?
                        <Typography className={ css.helpText } variant='body1' component='p'>Por favor introduce tu email para localizar tu cuenta</Typography>
                        :
                        <Typography className={ css.cardHeading } variant="h5" component="h5">Iniciar Sesión</Typography>
                }
                <TextField
                    className={ css.textField }
                    id="email"
                    name="email"
                    label="Correo electrónico"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                {!forgotPass ?
                    <TextField
                    className={ css.textField }
                        id="password"
                        name="password"
                        label="Contraseña"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    : alert.active ?
                        (<Alert icon={false} severity={alert.severity}>
                            {alert.text}
                        </Alert>)
                        : ""}
                {error ?
                    (<Alert icon={false} severity="error">
                        {error}
                    </Alert>) : ""}
                {forgotPass ?
                    <Button type="submit" variant="contained" color="primary" disabled={sendEmail ? true : false}>
                        Localizar Cuenta
                    </Button>
                    :
                    <ButtonBase type="button">
                        <Button
                            className={ css.btnInCard }
                            variant="text"
                            onClick={() => {
                                setSendEmail(false)
                                setForgotPass(true)
                                setError()
                                setAlert({ active: false, text: '', severity: 'error' })

                            }}
                        >
                            ¿Olvidaste tu contraseña?
                        </Button>
                    </ButtonBase>}
                {forgotPass ?
                    <ButtonBase type="button">
                        <Button className="login-sub-btn mt-2 mb-2" onClick={() => {
                            setSendEmail(false)
                            setForgotPass(false)
                            setAlert({ active: false, text: '', severity: 'error' })
                            setError()
                        }} color="primary">
                            Regresar a Inicio de Sesión
                        </Button>
                    </ButtonBase>
                    :
                    <Button
                        className={ css.btnIniciarSesion }
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={formik.values.password && formik.values.email ? false : true}
                    >
                        INICIAR SESIÓN
                    </Button>
                }
            </form >
        </Card>
        
    );
}

export default LoginCard;