import moment from 'moment'

const getGenTime = (timeString) => {
  let H = +timeString.substr(0, 2);
  let h = (H % 12) || 12;
  let ampm = H < 12 ? " AM" : " PM";
  return timeString = h + timeString.substr(2, 3) + ampm;
}

export function returnTimesInBetween(start, end) {
    var timesInBetween = [];
    var startH = parseInt(start.split(":")[0]);
    var startM = parseInt(start.split(":")[1]);
    var endH = parseInt(end.split(":")[0]);
    var endM = parseInt(end.split(":")[1]);
  
    if (startM == 30)
      startH++;
    for (var i = startH; i < endH; i++) {
      timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
      timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
    }
    timesInBetween.push(endH + ":00");
    if (endM == 30)
      timesInBetween.push(endH + ":30")
  
    return timesInBetween.map(getGenTime);
}

export function filterAvailableHours( usuario ) {
  let hours = []

    if( usuario ) {
        const horaInicial = new Date( usuario.preferencias.horaInicial )
        const horaFinal = new Date( usuario.preferencias.horaFinal )

        // If there are "exception hours in user's preferences"
        if( usuario.preferencias.horaInicialExcepcion && usuario.preferencias.horaFinalExcepcion ) {
            const horaInicialExcepcion = new Date( usuario.preferencias.horaInicialExcepcion )
            const horaFinalExcepcion = new Date( usuario.preferencias.horaFinalExcepcion )

            console.log( "Hay horas excepcion" )

            for(let hour = horaInicial.getHours(); hour < horaInicialExcepcion.getHours(); hour++) {
                hours.push(moment({ hour }).format('HH:mm'));
                hours.push(
                    moment({
                        hour,
                        minute: 30
                    }).format('HH:mm')
                );                
            }
            for(let hour = horaFinalExcepcion.getHours(); hour < horaFinal.getHours(); hour++) {
                hours.push(moment({ hour }).format('HH:mm'));
                hours.push(
                    moment({
                        hour,
                        minute: 30
                    }).format('HH:mm')
                );
            
            }
            //console.log( hours )
            return hours
        } else {
            // If there are NO "exception hours"
            for(let hour = horaInicial.getHours(); hour < horaFinal.getHours(); hour++) {
                hours.push(moment({ hour }).format('HH:mm'));
                hours.push(
                    moment({
                        hour,
                        minute: 30
                    }).format('HH:mm')
                );
            
            }
            //console.log( hours )
            return hours
        }           
        
    }
}