import { Navigate } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { getItem, setItem } from '../utility/localStorageControl'

const Private = ( { isAuthorized, setAuthorized, children } ) => {

    const token = getItem( 'westToken' )
    const user = getItem( 'westUser' )

    useEffect( () => {
        const checkAuth = async () => {
            //console.log( `App => Private => checkAuth executed.` )

            // first check localStorage data
            if ( ! user || ! token ) setAuthorized( false )

            try {
                const response = await axios.get( `${ process.env.REACT_APP_BACKEND_URI }auth`, { headers: {
                    'authorization': `Bearer ${ token }`
                } } )
                
                setAuthorized(true)
        
            } catch ( error ) {
                //console.log( error )
                if ( error.response.status === 403 ) {
                    //console.log( `App > Attempting to refresh the token...` )
                    if ( ! user ) return
                    const refresh = await axios.post( `${ process.env.REACT_APP_BACKEND_URI }auth/refreshtoken`, { 'refreshToken': user.refreshToken } )
                    //console.log( refresh )
                    //console.log( refresh.data )
                    setItem( 'westToken', refresh.data.accessToken )
                    if ( refresh.status === 200 ) {
                      setAuthorized( true )// Parent State
                    } else {
                        console.warn( 'Setting authorize to false' )
                        setAuthorized( false )// Parent State

                    }
                }
            }
        }

        checkAuth()
    } )

    if( ! isAuthorized ) {
        return <Navigate to={`/`} replace />
    }

    return children
}

export default Private