import { Navigate } from 'react-router-dom'
import Header from '../components/layout/Header/Header'
import Body from '../components/layout/Body'
import Footer from '../components/layout/Footer'
import SideBar from '../components/layout/SideBar/SideBar'
import Content from '../components/layout/Content'
import Integracion from '../components/layout/Integracion/Integracion'

const Integraciones = () => {
        return(
            <div className='d-flex flex-column pageBody'>
                <Header />
                <Body>
                    <SideBar/>
                    <Content contentMode={ `inline-wrap` }>
                        <Integracion platform={ `zoom` }/>
                        <Integracion platform={ `outlook` }/>
                    </Content>
                </Body>            
                <Footer />
                
            </div>
        )

}

export default Integraciones