import { useState } from 'react'
import Calendario from './Calendario'
import DatosUsuario from './DatosUsuario'
import Confirmacion from './Confirmacion'
import { Button } from '@mui/material'
import FooterSolicitud from '../../components/layout/FooterSolicitud'
import './Solicitud.css'

const Solicitud = () => {

    const [ formData, setFormData ] = useState({
        paso: 1,
        fecha: '',
        hora: '',
        campos: [],
    })

    const continuar = () => {
        const newData = {...formData}
        newData.paso = formData.paso + 1
        setFormData( newData )
    }

    const retroceder = () => {
        const newData = {...formData}
        newData.paso = formData.paso - 1
        setFormData( newData )
    }

    switch( formData.paso ) {
        case 1:
            return(
                <div className='d-flex flex-column justify-content-between solicitudContainer h-100'>
                    <Calendario />
                    <Button
                        className='text-uppercase align-self-end me-5 mb-5'
                        variant="contained"
                        size='large'
                        onClick={ continuar }
                    >
                        CONTINUAR
                    </Button>
                    <FooterSolicitud />
                    
                </div>
            )

        case 2:
            return(
                <div className='d-flex flex-column justify-content-between solicitudContainer h-100'>
                    <DatosUsuario />
                    <div className='d-flex justify-content-end gap-3 mb-5'>
                        <Button
                            className='text-uppercase align-self-end'
                            variant="contained"
                            size='large'
                            onClick={ retroceder }
                        >
                            atrás
                        </Button>
                        <Button
                            className='text-uppercase align-self-end'
                            variant="contained"
                            size='large'
                            onClick={ continuar }
                        >
                            confirmar
                        </Button>
                    </div>

                    <FooterSolicitud/>
                </div>
            )

        case 3:
            return(
                <div className='d-flex flex-column justify-content-between solicitudContainer h-100'>
                    <Confirmacion />
                    <FooterSolicitud />
                </div>
                
            )

        default:
            return(
                <div className='d-flex flex-column justify-content-between solicitudContainer h-100'>
                    <Calendario />
                    <Button onClick={ continuar }>Siguiente</Button>
                    <FooterSolicitud />
                </div>
            )
    }

}

export default Solicitud