import { sendRequest } from "../utility/utility";
import { getItem } from "../utility/localStorageControl";

const tokenName = 'westToken'

const postLogin = async (email, password) => {
    const route = "usuarios/login";
    const request = {
        method: 'post',
        headers: {
            "method": "POST",
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        data: {
            email,
            password
        }
    };
    return await sendRequest(route, request);
}

const postForgotPass = async (email) => {
    const route = "auth/password-reset";
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: {
            email
        }
    };
    return await sendRequest(route, request);
}

const changePass = async (userId, input, password, passwordConfirmation) => {
    const token = getItem(tokenName);
    const route = `auth/password-change/${ userId }`;
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
        data: {
            input,
            password,
            passwordConfirmation
        }
    };
    return await sendRequest(route, request);
}

const resetPass = async ( userId, token, password ) => {
    const route = `auth/password-reset/${ userId }/${ token }`
    const request = {
        method: `POST`,
        headers: {
            "Content-Type": `application/json`,
            Accept: `application/json`,
        },
        data: {
            password,
        }
    }
    return await sendRequest(route, request)
}

const resetPassBackup = async (email, otp, newPassword) => {
    const route = "auth/resetpassword";
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        data: {
            email,
            otp,
            newPassword
        }
    };
    return await sendRequest(route, request);
}

export {
    postLogin,
    postForgotPass,
    changePass,
    resetPass
}