import Header from "../components/layout/Header/Header"
import RegisterCard from "../components/login/RegisterCard"
import Footer from "../components/layout/Footer"

import './Register.css'

const Register = () => {

    return(
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <Header isAnonymous />
            <div className="d-flex justify-content-center registerBody w-100">
                <RegisterCard />
            </div>
            <Footer/>
        </div>
    )

}

export default Register