import { Image } from "react-bootstrap"
import { Typography } from "@mui/material"

const Encabezado = ( { titulo, mensajeInicial, logotipo } ) => {

  return (
    <div className="d-flex flex-column align-items-center col-12" style={ container }>

        <Image src={ logotipo } style={ { width: 170 } } />

        { titulo && (<Typography className="mt-5" variant="h3" component="h3">{ titulo }</Typography>) }

        { mensajeInicial && (<Typography className="mt-4" variant="h6" component="h6">{ mensajeInicial }</Typography>) }

    </div>
  )
}

const container = {
    marginTop: '4.938rem',
}



export default Encabezado