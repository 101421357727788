import LogoZoom from '../../../assets/img/zoom-logo.png'
import LogoOutlook from '../../../assets/img/outlook-logo.png'
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import './Integracion.css'

const Integracion = ( { platform } ) => {

    const platformLogos = {
        zoom: LogoZoom,
        outlook: LogoOutlook,
    }

    const creds = {
        apiKey: 'jHs2VzSwRLWuRVqc9nfh0Q',
        redirectUrl: 'https://c12c-2806-1016-9-e785-15af-431a-a9da-1e0d.ngrok.io/zoomcallback'
    }

    const authZoom = () => {
        console.log( `Login into Zoom...` )
        window.location.replace( `https://zoom.us/oauth/authorize?response_type=code&client_id=${ creds.apiKey }&redirect_uri=${creds.redirectUrl}` )
    }

    const handleAuthorization = () => {
        switch ( platform ) {
            case `zoom`:
                authZoom()
                break
        }
    }

    return(
        <div className="integracion d-flex flex-column border col-5 p-4 m-2">
            <div className="d-flex justify-content-between pb-4 align-items-center">
                <div><img className='platformLogo' src={ platformLogos[platform]} alt={ platform }/></div>
                <div>
                    <Button
                        className='btnScheduler text-uppercase'
                        type="submit"
                        variant="contained"
                        onClick={ handleAuthorization }
                    >
                        CONECTAR CON { platform }
                    </Button>
                </div>
            </div>
            <div>
                <Typography className='text-dark contentText' variant='body1'>
                    Integrar tu calendario y reuniones de { platform } para crear sesiones 
                    de { platform } automaticamente al reservar un espacio en el calendario. 
                    También se sincronizará tu calendario de { platform } para bloquear 
                    las fechas y horarios no disponibles.
                </Typography>
            </div>
        </div>
    )

}

export default Integracion