import Encabezado from "./Encabezado"
import { Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { TextField, RadioGroup, FormControl, FormControlLabel, Radio } from "@mui/material"
import { LocalizationProvider, StaticDatePicker } from "@mui/lab"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSearchParams } from "react-router-dom"
import { setItem } from "../../utility/localStorageControl"
import { filterAvailableHours } from "../../utility/TimeTools"
import './Calendario.css'
import axios from "axios"

const Calendario = () => {

    const [ urlParams ] = useSearchParams()
    const uid = urlParams.get( 'uid' )

    const [ selectedDate, setSelectedDate ] = useState(
        new Date( Date.now() )
    )

    const [ usuario, setUsuario ] = useState(null)
    const [ availableHours, setAvailableHours ] = useState(null)

    useEffect( () => {
        const getUsuario = async () => {
            try {
                const response = await axios.get( `${ process.env.REACT_APP_BACKEND_URI }usuarios/${ uid }` )
                setUsuario( response.data )
            } catch ( error ) {
                console.warn( error )
            }
        }

        getUsuario()
    }, [] )

    useEffect( () => {
        setItem( 'datosUsuario', usuario )
    }, [usuario] )

    useEffect( () => {
        setAvailableHours( filterAvailableHours(usuario) )
    }, [usuario] )

    if( usuario ) {
        return(
            <div className="d-flex flex-column align-items-center mb-5">
                <Encabezado titulo={ usuario.formulario.titulo } mensajeInicial={ usuario.formulario.mensajeInicial } logotipo={ usuario.formulario.logotipo } />
                <Typography className="mt-5 mb-3" variant="h6" component="h6" style={ subtitle }>{ `Selecionar fecha y hora de la reunión` }</Typography>
    
                <div className="d-flex flex-wrap justify-content-center gap-4">
                    
                        <LocalizationProvider dateAdapter={ AdapterDayjs }>
                            <div className="calendarborder">
                                <StaticDatePicker
                                    views={ ['month', 'day'] }// Tiene que estar en orden de mas a menos. ¿Quién lo diría?.
                                    displayStaticWrapperAs='desktop'
                                    disablePast
                                    label='Fecha'
                                    openTo="day"
                                    value={ selectedDate }
                                    onChange={ newValue => {
                                        setSelectedDate( newValue )
                                    } }
                                    renderInput={(params) => <TextField {...params}/>}
                                />
                            </div>
    
                            <div className="d-flex flex-column calendarborder align-items-start scrollable">
    
                                <Typography className='p-3' variant="h5" component="h5" sx={{ textAlign: "left", color: 'black', }}>{ `Domingo 31 de Julio 2021` }</Typography>
                                <hr className="col-11 align-self-center m-0" />
    
                                <div className="d-flex col-12 m-0">
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={'00:00'}
                                    >
                                        
                                        <FormControl>
                                            <RadioGroup name={ `hour-selectors` }>
                                            {
                                                availableHours &&
                                                    availableHours.map( (availableHour, index) => (
                                                        <FormControlLabel key={ index } value={ availableHour } control={<Radio />} label={ availableHour } />
                                                    ) )
                                            }
                                            </RadioGroup>                                                        
                                        </FormControl>
                                    </RadioGroup>
                                </div>
    
                            </div>
                        </LocalizationProvider>
                    
                    
                </div>
                
            </div>
        )
    
    } else {
        <></>
    }
}

    

const subtitle = {
    color: 'rgba(0, 0, 0, 0.6)',
}

export default Calendario